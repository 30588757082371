<template>
  <div>
    <div id="video-container">
      <video id="spPlayer" class="video-js" width="100%" />
    </div>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import 'video.js/dist/video-js.css';
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css';
import '@/assets/scss/videojs-sp-skin.scss';
import VideoJs from 'video.js';

require('videojs-seek-buttons');
require('videojs-flash');

export default {
  extends: BaseComponent,
  props: {
    autoplay: {
      type: Boolean,
      default() {
        return false;
      },
    },
    course: {
      type: Object,
      default () {
        return {};
      }
    },
    lesson: {
      type: Object,
      default () {
        return {};
      }
    },
    modalContent: {
      type: String,
      default() {
        return '';
      },
    },
    theaterMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      file: {
        id: null,
        type: null,
      },
      filterValue: this.$route.query['filterby'],
      ready: false,
      viewRecorded: false,
      techOrder: ['html5', 'flash'],
    };
  },
  computed: {
    isNew: {
      get: function () {
        return this.$store.getters['software/getIsNew'];
      },
      set: function (data) {
        this.$store.commit('software/setIsNew', data);
      }
    },
    videoPlayer: {
      get: function() {
        return this.$store.getters['player/getVideoPlayer'];
      },
      set: function(newValue) {
        this.$store.commit('player/setVideoPlayer', newValue);
      },
    },
    videoPlayerPlaybackRate: {
      get: function(){
        return this.$store.getters['user/getUserSettingVideoPlaybackRate'];
      },
      set: function(newValue){
        if (this.isLoggedIn) {
          this.$store.dispatch('user/updateVideoPlaybackRate', newValue);
        }
        this.videoPlayer.playbackRate(newValue);
      }
    },
  },
  watch: {
    'autoplay': function(val) {
      this.videoPlayer.autoplay(val);
    },
    'lesson': function (val, oldVal) {
      this.init();
      this.ready = false;
    },
    'modalContent': function() {
      // trigger the cert modal when content is sent from course component JCS05242018
      this.openModal(this.modalContent);
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    this.videoPlayer.dispose();
    this.videoPlayer = null;
  },
  methods: {
    init() {
      this.playerInitialize();
      this.playerSetSrc(this.lesson.url);
    },
    /**
     * Initialize player settings
     */
    playerInitialize() {
      if (!this.videoPlayer) {
        this.videoPlayer = VideoJs('spPlayer', {
          techOrder: this.techOrder,
          controls: true,
          autoplay: this.autoplay,
          preload: 'auto',
          aspectRatio: '16:9',
          playbackRates: [0.75, 1, 1.25, 1.5],
          plugins: {
            seekButtons: {
              back: 10,
            },
          },
          flash: {
            swf: this.SPENV.CDN_URL + '/vendor/videojs-swf/video-js.swf',
          },
        });
        this.videoPlayer.defaultPlaybackRate(this.videoPlayerPlaybackRate);
        this.playerSetupEvents();
      }
    },
    /**
     * Set player source video
     */
    playerSetSrc(url) {
      if (url) {
        this.videoPlayer.src({type: 'video/mp4', src: url});
        this.viewRecorded = false;

        // Update playback rate after setting src
        this.videoPlayer.defaultPlaybackRate(this.videoPlayerPlaybackRate);
        this.videoPlayer.playbackRate(this.videoPlayerPlaybackRate);

        // Set Captions
        if (this.lesson.captions) {
          this.playerSetCaptions(this.lesson.captions);
        }

      }
    },
    /**
     * Player close captions language setup
     */
    playerSetCaptions(captions) {
      if (captions) {
        captions.forEach(lang => {
          // Videojs does not respond to 301/302. Make sure request is https
          lang.src = 'https:' + lang.src;
          this.videoPlayer.addRemoteTextTrack(lang, false);
        });
      }
    },
    /**
     * Get current video player time
     */
    playerGetTime() {
      return this.videoPlayer.currentTime();
    },
    /**
     * Dispatch event in player store to record video view in db
     */
    recordView() {
      if (this.isLoggedIn) {
        const data = {
          lessonID: this.lesson.rowid,
          referrer: (this.filterValue === 'new' && this.isNew) ? 'new' : '',
          viewed_as_new: this.isNew
        };
        this.$store.dispatch('software/recordLessonView', data).then(
          (response) => {
            this.$emit('record', {lesson: this.lesson, cert: response});
          }
        );
      }
    },
    /**
     * populate and display modal on top of the video player, this will pause the player if something is playing and resume once the member closes the modal
     */
    openModal(modalContent) {
      // where the magic happens
      const contentEl = document.createElement('div');
      // probably better to just build the entire thing via DOM methods
      contentEl.innerHTML = modalContent;
      this.videoPlayer.createModal(contentEl);
    },
    /**
     * Player set events including recording view after 3 sec and showing next lesson after current lesson is complete jhamm 5/10/18
     */
    playerSetupEvents() {
      if (!this.ready) {
        this.ready = true;

        this.videoPlayer.on('loadeddata', () => {
          this.$emit('last', this.lesson.rowid);
        });

        this.videoPlayer.on('timeupdate', () => {
          if (this.viewRecorded === false && this.playerGetTime() >= 3) {
            this.viewRecorded = true;
            this.recordView();
            this.analyticsClickEvent('Lesson', 'View', 'lessonId: ' + this.lesson.rowid + ', type: video',);
          }
        });

        this.videoPlayer.on('ended', () => {
          if (!this.videoPlayer.paused()) {
            this.videoPlayer.pause();
          }
          this.$emit('next', this.lesson.rowid);
        });

        this.videoPlayer.on('ratechange', () => {
          if (this.videoPlayer.playbackRate() != this.videoPlayerPlaybackRate) {
            this.videoPlayerPlaybackRate = this.videoPlayer.playbackRate();
          };
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
